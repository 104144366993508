.similar-product-slider {
  margin-bottom: 32px;
  width: 100%;

  .swiper {
    padding-bottom: 30px;
  }

  .swiper-button-next {
    display: none;
    right: 0;
    &:after {
      background: url(../images/product/swiper-right.svg) center center no-repeat transparent;
      width: 24px;
      height: 24px;
      content: '';
    }
  }
  .swiper-button-prev {
    display: none;
    left: 0;
    &:after {
      background: url(../images/product/swiper-left.svg) center center no-repeat transparent;
      width: 24px;
      height: 24px;
      content: '';
    }
  }

  &__head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__title {
    color: #212529;
    font-weight: bold;
    font-size: 22px;
    span {
      color: #838383;
    }
  }

  &__link {
    a {
      color: #838383;
    }
  }

  &__products {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }

  &__product {
    width: 72px;
  }

  &__image {
    border: 1px solid #E3E3E3;
    margin-bottom: 12px;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    img {
      height: 100%;
      width: auto;
    }
  }

  &__name {
    margin-bottom: 12px;
    a {
      font-weight: bold;
      font-size: 12px;
      color: #212529;
    }
  }

  &__price {
    font-weight: bold;
    font-size: 12px;
    color: #212529;
  }
}

@media (min-width: $breakpoint-mobile) {
  .similar-product-slider {
    .swiper {
      padding-bottom: 0;
    }

    .swiper-pagination {
      display: none;
    }

    .swiper-button-next {
      display: flex;
    }

    .swiper-button-prev {
      display: flex;
    }

    &__products {
      padding-right: 42px;
      padding-left: 42px;
    }
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  .similar-product-slider {
    &__image {
      height: 120px;
      width: 120px;
    }
  }
}