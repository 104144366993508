.noUi-target {
    background: transparent;
    border-radius: 0;
    border: medium none;
    box-shadow: unset;
}

.noUi-base, .noUi-connects {
    height: 4px;
}

.noUi-connect {
    background: #D4100F;
}

.noUi-horizontal .noUi-handle {
    top: -10px;
    width: 24px;
    height: 24px;
    border: 2px solid #D4100F;
    background-color: white;
    border-radius: 50%;
    box-shadow: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
}
.noUi-horizontal .noUi-handle:hover {
    background-color: #f0f6ff;
}

.noUi-handle:before {
    display: none;
}

.noUi-handle:after {
    content: "";
    width: 8px;
    height: 8px;
    background: #D7D7D7;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    transition: 0.1s;
    position: absolute;
    left: 6px;
}

.noUi-handle:active:after {
    opacity:1;
    visibility:visible;
}

.noUi-marker-large {
    display: none;
}

.noUi-pips-horizontal {
     padding: 0;
     top: 10px;
    width: 100%;
}

.noUi-value-large {
    font-size: 10px;
    color: #656565;
}

.noUi-pips {
    z-index: -10;
}
