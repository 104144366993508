.products-number-selector {
  display: flex;
  height: 40px;
  justify-content: space-between;
  flex-grow: 1;

  &__plus, &__minus {
    transition: 0.3s;
    cursor: pointer;
    width: 33.33%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    background: #F3F3F3;

    &:hover {
      background: #E3E3E3;
    }
  }

  &__input {
    width: 33.33%;
    display: flex;
    background: #F3F3F3;
    input {
      width: 100%;
      border: none;
      background: none;
      font-family: "PB", sans-serif;
      font-size: 14px;
      text-align: center;
      outline: medium none;
    }
  }

  &__price {
    height: 54px;
    display: flex;
    padding: 0 30px;
    align-items: center;
    font-family: "PB", sans-serif;
    font-size: 18px;
  }

  &__cart {
    flex: 1;
    height: 54px;
    background: url(../images/basket_w.svg) 24px center no-repeat #D30F0E;
    transition: 0.3s;
    color: #fff;
    display: flex;
    align-items: center;
    padding-left: 30px;
    text-decoration: none;
    font-family: "PB", sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    z-index: 2;
    position: relative;
    border: medium none;
    outline: medium none;
  }

  &__total {
    font-size: 18px;
    font-family: "PB", sans-serif;
    text-transform: uppercase;
    margin-left: 12px;
    display: flex;
    align-items: center;
  }
}

.products-number-selector {
  &.in-product {
    height: 60px;
    user-select: none;
    column-gap: 16px;

    .products-number-selectors {
      display: flex;
      flex: 0 0 calc(50% - 16px);
      flex-direction: row;
    }

    .products-number-selector__cart {
      height: 60px;
    }

    .products-number-selector__price {
      background-color: #fff;
    }
  }


}