.footer {
  height: 330px;
  background: #000000;
  position: relative;

  a {
    color: #fff;
  }

  ul {
    list-style: none;
    margin: 0;
    li {
      margin-bottom: 20px;
    }
  }

  &__wrapper {
    width: 1440px;
    margin: 0 auto;
    padding-top: 70px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__content {
    display: flex;
  }

  &__price-list {
    width: 196px;
    height: 54px;
    background: #D30F0E;
    transition: 0.3s;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-family: "PB", sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 30px;
      top: 50%;
      margin-top: -5px;
      width: 10px;
      height: 11px;
      background: url(../images/price.svg) center center no-repeat;
    }

    &:hover:before {
      animation:price_animation 1s infinite linear;
    }
  }

  &__phones {
    background: url(../images/phone.svg) 0 center no-repeat;
    display: flex;
    flex-direction: column;
    padding-left: 36px;
  }

  &__address {
    background: url(../images/map.svg) 0 center no-repeat;
    display: flex;
    flex-direction: column;
    padding-left: 36px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &__contacts {
    padding-left: 36px;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 26px;
  }

  &__menu {
    display: flex;
    justify-content: center;
    img {
      height: auto;
      width: 100%;
      max-width: 180px;
    }
  }

  &__copyrights {
    color: #7F7F7F;
  }
}

@media (max-width: $breakpoint-mobile - 1) {
  .footer {
    height: auto;

    &__wrapper {
      max-width: 420px;
      width: 100%;
      padding: 30px 20px;
    }

    &__content {
      flex-direction: column;
      align-items: center;
    }

    &__menu {
      height: 180px;
      width: 100%;
      order: 2;
      margin-top: 20px;
    }

    &__price-list {
      order: 1;
      margin: 0;
    }

    &__info {
      order: 2;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    &__address {
      margin-top: 24px;
    }

    &__copyrights {
      order: 3;
      text-align: center;
    }
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  .footer {

    &__wrapper {
      max-width: 680px;
      width: 100%;
      padding: 30px 20px;
    }

    &__content {
      flex-wrap: wrap;

      &:before {
        content: "";
        width: 100%;
        order: 3;
      }
    }

    &__menu {
      order: 4;
      margin-top: 20px;
      width: 100%;
      height: 180px;
      margin-bottom: 20px;
    }

    &__price-list {
      order: 1;
      margin: 0;
      width: 185px;
      position: absolute;
    }

    &__info {
      order: 2;
      display: flex;
      margin-left: 0;
      flex: 1 1 auto;
      justify-content: space-between;
      height: 54px;
      align-items: center;
      flex-wrap: wrap;

      &:before {
        content: "";
        width: 185px;
        display: flex;
      }
    }

    &__contacts {
      flex: 1 1 auto;
    }

    &__copyrights {
      display: flex;
      justify-content: center;
      order: 5;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop - 1) {
  .footer {

    &__wrapper {
      width: 852px;
      padding: 35px 0;
    }

    &__content {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    &__price-list {
      order: 1;
      margin: 0 0 30px;
    }

    &__menu {
      flex: 1 1 auto;
      margin-left: 0;
      order: 3;
      justify-content: center;
      height: 200px;
      img {
        max-width: 200px;
      }
    }

    &__info {
      order: 4;
    }

    &__phones {
      margin-bottom: 35px;
    }

    &__copyrights {
      display: flex;
      justify-content: center;
      order: 5;
    }
  }
}

@media (min-width: $breakpoint-laptop) and (max-width: $breakpoint-desktop - 1) {
  .footer {
    &__wrapper {
      width: 1146px;
    }

    &__menu {
      flex-grow: 1;
      justify-content: center;
      height: 200px;
      img {
        max-width: 200px;
      }
    }

    &__phones {
      margin-bottom: 35px;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .footer {
    &__wrapper {
      width: 1440px;
    }

    &__menu {
      flex-grow: 1;
      justify-content: center;
      height: 200px;
      img {
        max-width: 200px;
      }
    }

    &__phones {
      margin-bottom: 35px;
    }
  }
}

@keyframes price_animation{
  0%{
    transform:translateY(0px);
  }
  25%{
    transform:translateY(5px);
  }
  50%{
    transform:translateY(0px);
  }
  75%{
    transform:translateY(-5px);
  }
  100%{
    transform:translateY(0px);
  }
}