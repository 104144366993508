.product-cart {
  display: flex;
  column-gap: 24px;

  &__button {
    flex: 0 0 148px;
    height: 54px;
    background-color: #D30F0E;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 22px 0;
    text-decoration: none;
    font-family: "PB", sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
    border: medium none;
    justify-content: center;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  &__add-to-cart {
    background: url(../images/basket_w.svg) 0 center no-repeat #D30F0E;
    padding-left: 28px;
  }

  &__to-cart {
    background: url(../images/cart-arrow-right.svg) 0 center no-repeat #D30F0E;
    padding-left: 34px;
  }
}

.product-cart-number-selectors {
  display: flex;
  flex-direction: row;
  flex: 0 0 188px;

  &__minus, &__plus {
    align-content: center;
    align-items: center;
    background: #F3F3F3;
    border: medium none;
    cursor: pointer;
    display: flex;
    flex: 0 0 33.3333%;
    justify-content: center;
    transition: 0.3s;
  }

  &__input {
    flex: 0 0 33.3333%;
    border: none;
    background: #F3F3F3;
    font-family: "PB", sans-serif;
    font-size: 14px;
    text-align: center;
    outline: medium none;
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  .product-cart-number-selectors {
    flex: 0 0 38%;
  }
}
