.property-tooltip {
  position: relative;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0);
    z-index: 100;
  }

  &__button {
    cursor: pointer;
    mask: url(../images/product/q-mark.svg) no-repeat center center;
    background-color: #838383;
    border: medium none;
    flex-basis: 18px;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    &:hover {
      background-color: #D30F0E;
    }
  }

  &__close-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 14px;
    position: relative;
  }

  &__close {
    border: medium none;
    width: 14px;
    height: 14px;
    mask: url(../images/product/close.svg) no-repeat center center;
    background-color: #838383;
    right: -14px;
    position: absolute;
    top: -14px;
  }

  &__header {
    color: #838383;
    font-weight: bold;
    margin-bottom: 14px;
  }

  &__container {
    position: absolute;
    box-shadow: 0 39px 104px 0 rgba(0, 0, 0, 0.15);
    padding: 32px;
    background-color: #fff;
    width: 320px;
    z-index: 110;
  }

  &__content {
    color: #212529;
    font-weight: normal;
    font-family: 'PR', sans-serif;
  }

  &__read-more {
    color: #838383;
    cursor: pointer;
    font-family: 'PB', sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-top: 16px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
