.frontpage-categories {
  display: inline-flex;
  flex-flow: row wrap;
  gap: 2%;

  &__item {
    flex-grow: 1;
    font-size: 22px;
    font-weight: bold;
    width: 18%;
    height: 265px;
    background-color: #F0F0F0;
    background-repeat: no-repeat;
    background-position-x: 80%;
    background-position-y: 80%;
    background-size: 50%;
    border-bottom: 5px solid #D30F0E;
    padding: 32px 36px;
    margin-bottom: 28px;
    position: relative;

    &:hover {
      background-color: #D30F0E;

      .frontpage-categories__link {
        color: #fff;
      }
    }
  }

  &__item:nth-child(1), &__item:nth-child(6) {
    width: 40%;
  }

  &__item:nth-child(6) {
    background-size: initial;
  }

  &__link {
    color: #000;
    text-decoration: none;
    &:hover {
      color: #000;
      text-decoration: none;
    }
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      content: "";
    }
  }
}

@media (max-width: $breakpoint-mobile - 1) {
  .frontpage-categories {
    display: none;
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
  .frontpage-categories {
    &__item {
      width: 45%;
    }
    &__item:nth-child(1), &__item:nth-child(6) {
      width: 45%;
    }
    &__item:nth-child(1) {
      background-position-y: 80%;
      background-size: 80%;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
  .frontpage-categories {
    &__item {
      width: 30%;
    }
    &__item:nth-child(1), &__item:nth-child(6) {
      width: 30%;
    }
    &__item:nth-child(1) {
      background-position-y: 80%;
      background-size: 80%;
    }
  }
}

@media (min-width: $breakpoint-laptop) and (max-width: $breakpoint-desktop) {
  .frontpage-categories {
    &__item {
      width: 28%;
    }
    &__item:nth-child(1) {
      width: 62%;
    }
    &__item:nth-child(6) {
      width: 28%;
    }
  }
}