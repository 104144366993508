.thank-you {
  text-align: center;

  &__image {
    max-width: 284px;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    font-size: 48px;
    font-family: "PB", sans-serif;
  }

  p {
    max-width: 468px;
    margin-top: 30px;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: $breakpoint-mobile - 1) {
  .thank-you {
    h1 {
      font-size: 24px;
    }
    p {
      font-size: 18px;
    }
  }
}