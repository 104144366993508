.not-found {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 600px;
  padding: 130px 0 70px;

  &__image {
    width: 600px;
    transform: translate(12%, 0px);

    img {
      width: 100%;
    }
  }

  &__text {
    font-size: 18px;
    text-align: center;
  }

  &__link {
    a {
      color: #fff;
      transition: 0.3s;
      background: #D30F0E;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 196px;
      height: 54px;
      text-transform: uppercase;
      font-family: "PB", sans-serif;
      font-size: 12px;

      &:hover, &:focus {
        background: #FF3D3D;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: $breakpoint-mobile - 1) {
  .not-found {
    min-height: 300px;
    padding: 30px 0 0;

    &__image {
      width: 70%;
    }
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  .not-found {
    min-height: 430px;
    padding: 60px 0 0;

    &__image {
      width: 70%;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop - 1) {
  .not-found {
    min-height: 540px;
    padding: 100px 0 40px;
  }
}