.cart {
  display: flex;
  justify-content: space-between;

  &__products {
    flex-grow: 1;
    margin-right: 36px;
  }
}

.cart-product {
  width: 100%;
  min-height: 223px;
  position: relative;
  border: 1px solid #E5E5E5;
  padding: 30px;
  margin-top: -1px;
  display: flex;
  align-items: center;
  transition: all 3s ease-out;

  &__image {
    width: 180px;
    height: 162px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  &__info {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  &__name {
    font-size: 24px;
    font-family: "PB", sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    width: 500px;

    a {
      color: #000;
    }
  }

  &__divider {
    display: flex;
    flex-direction: row;
  }

  &__quantity {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    .products-number-selector {
      &__minus, &__plus, &__input {
        width: 30px;
      }
    }
  }

  &__remove {
    align-items: center;
    width: 100px;
    display: flex;
    color: #7F7F7F;
    font-size: 16px;
    cursor: pointer;
    margin-left: 30px;
    min-height: 19px;

    a {
      color: #7F7F7F;
      display: inline-block;
      padding-left: 26px;
      background: url(../images/delete.svg) 0 center no-repeat;
    }
  }
}

.cart-product__removed {
  transform: translateX(-200%);
  height: 0 !important;
  min-height: 0;
  border: none;
  padding: 0;
  overflow: hidden;
  transition: transform 0.5s 0s, height 0.4s 0.3s, padding 0.4s 0.3s, border 0.4s 0.3s, min-height 0.4s 0.3s;
}

.cart-form {
  width: 264px;
  background: #F0F0F0;
  padding: 44px 30px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 auto;

  &__goods {
    color: #999999;
    display: flex;
    font-size: 16px;
    width: 100%;
    align-items: baseline;

    &:before {
      content: "";
      flex: 1 1 auto;
      order: 2;
      border-bottom: dotted 2px #999;
    }
  }

  &__goods-title {
    order: 1;
    padding-right: 7px;
  }

  &__goods-count {
    padding-left: 7px;
    order: 3;
  }

  &__total {
    display: flex;
    font-size: 16px;
    width: 100%;
    align-items: baseline;
    font-family: "PB", sans-serif;
    margin-top: 20px;

    &:before {
      content: "";
      flex: 1 1 auto;
      order: 2;
      border-bottom: dotted 2px #000;
    }
  }

  &__total-title {
    order: 1;
    padding-right: 7px;
  }

  &__total-sum {
    padding-left: 7px;
    order: 3;
    font-size: 18px;
    text-transform: uppercase;
  }

  &__user-info {
    margin-top: 40px;

    input {
      width: 100%;
      height: 36px;
      background: #fff;
      padding: 0 10px;
      display: flex;
      align-content: center;
      align-items: center;
      border: none;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  &__submit {
    color: #fff;
    transition: background 0.3s;
    background: #D30F0E;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 54px;
    text-transform: uppercase;
    font-family: "PB", sans-serif;
    font-size: 12px;
    cursor: pointer;
    margin-top: 20px;
    border: none;
    position: relative;
  }

  &__phone {
    margin-top: 40px;
  }

  &__phone-description {
    color: #656565;
    font-size: 12px;
  }

  &__phone-link {
    font-size: 16px;
    font-family: "PB", sans-serif;
    margin-top: 10px;
    a {
      color: #000;
    }
  }
}

.cart-pig {
  display: none;
  position: absolute;
  z-index: 20;

  &.active {
    display: block;
    position: fixed;
  }
}

@media (max-width: $breakpoint-mobile - 1) {
  .cart {
    flex-direction: column;

    &__products {
      width: 100%;
      margin-right: 0;
    }
  }

  .cart-product {
    padding: 30px 20px;
    height: auto;
    flex-direction: column;
    min-height: 0;

    &__info {
      width: 100%;
      flex-direction: column;
    }

    &__name {
      width: 100%;
      padding: 0;
      margin-top: 30px;
    }

    &__divider {
      width: 100%;
    }

    &__divider {
      flex-direction: column;
    }

    &__quantity {
      margin-top: 30px;
      width: 100%;
    }

    &__remove {
      margin-left: 0;
      margin-top: 30px;
      width: 100%;
    }
  }

  .cart-form {
    width: 100%;
    margin-top: 50px;
    padding: 30px 20px;
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  .cart {
    flex-direction: column;

    &__products {
      width: 100%;
      margin-right: 0;
    }
  }

  .cart-product {
    align-items: start;

    &__image {
      width: 162px;
      padding-right: 20px;
    }

    &__info {
      flex-grow: 1;
      padding-left: 20px;
      flex-direction: column;
      align-content: start;
    }

    &__name {
      width: 100%;
      padding: 0;
      margin-top: 0;
    }

    &__divider {
      width: 100%;
      flex-direction: column;
    }

    &__quantity {
      margin-top: 30px;
      width: 100%;
    }

    &__remove {
      margin-left: 0;
      margin-top: 30px;
      width: 100%;
    }
  }

  .cart-form {
    width: 100%;
    margin-top: 50px;
    padding: 30px 20px;

    &__divider {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
      align-items: center;
    }

    &__submit {
      width: 195px;
      margin-top: 0;
    }

    &__phone {
      text-align: right;
      margin-top: 0;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop - 1) {
  .cart {
    flex-direction: column;

    &__products {
      width: 100%;
      margin-right: 0;
    }
  }

  .cart-product {
    &__name {
      width: 380px;
    }

    &__divider {
      flex-direction: column;
    }

    &__quantity {
      width: 100%;
    }

    &__remove {
      margin-left: 0;
      margin-top: 12px;
      width: 100%;
    }
  }

  .cart-form {
    width: 100%;
    margin-top: 50px;
    height: auto;
    justify-content: flex-end;
    align-items: flex-end;

    &__info, &__form {
      width: 100%;
    }

    &__user-info {
      display: flex;
      flex-direction: row;

      input {
        margin-right: 10px;
      }

      input:last-child {
        margin-right: 0;
      }
    }

    &__divider {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
      align-items: center;
    }

    &__submit {
      width: 195px;
      margin-top: 0;
    }

    &__phone {
      text-align: right;
      margin-top: 0;
    }
  }
}

@media (min-width: $breakpoint-laptop) and (max-width: $breakpoint-desktop - 1) {
  .cart-product {
    &__name {
      width: 380px;
    }

    &__divider {
      flex-direction: column;
    }

    &__quantity {
      width: 100%;
    }

    &__remove {
      margin-left: 0;
      margin-top: 12px;
      width: 100%;
    }
  }
}