.cart-badge {
  padding: 0 5px;
  background: #D30F0E;
  color: #fff;
  font-size: 12px;
  font-family: "PB", sans-serif;
  position: absolute;
  line-height: 20px;
  top: -27px;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #d30e0e transparent transparent transparent;
    bottom: -4px;
    left: 50%;
    margin-left: -4px;
  }
}

.header-sticky {
  .cart-badge {
    top: unset;
    right: 100%;
    margin-right: 4px;
    &:after {
      border-width: 4px 0 4px 4px;
      border-color: transparent transparent transparent #d30e0e;
      bottom: 50%;
      margin-bottom: -4px;
      left: 100%;
      margin-left: 0;
    }
  }
}