.header__mobile-menu {
  position: relative;
  z-index: 20;
}

#mobile-menu-toggle {
  display: block;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    text-decoration: none;
    color: #232323;
    -webkit-transition: color 0.3s ease;
    -o-transition: color 0.3s ease;
    transition: color 0.3s ease;

    &:hover {
      color: #D30F0E;
    }

    &.active {
      color: #D30F0E;
    }
  }

  input {
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    top: -7px;
    left: -5px;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
  }

  label {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;

    span {
      display: none;
    }

    .menu-control {
      display: flex;
      width: 38px;
      height: 30px;
      align-items: flex-end;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      position: relative;
      z-index: 10;

      span {
        display: block;
        width: 30px;
        height: 2px;
        background: #000;
        transition: 0.3s;
      }

      &:before {
        content: "";
        height: 2px;
        background: #000;
        width: 100%;
        transition: 0.3s;
        transform-origin: right center;
      }

      &:after {
           content: "";
           height: 2px;
           background: #000;
           width: 100%;
           transition: 0.3s;
           transform-origin: right center;
         }
    }
  }

  input:checked + label .menu-control {
    &:before {
      content: "";
      height: 2px;
      background: #000;
      width: 50%;
      transform-origin: right center;
      transform: translateY(14px) rotate(45deg);
    }

    span {
      display: block;
      width: 30px;
      height: 2px;
      background: #000;
      transition: 0.3s;
    }

    &:after {
         content: "";
         height: 2px;
         background: #000;
         width: 50%;
         transform-origin: right center;
         transform: translateY(-14px) rotate(-45deg);
       }
  }

  input:checked ~ .mobile-menu__wrapper {
    right: 0;
  }
}

.mobile-menu__wrapper {
  position: fixed;
  height: 100%;
  width: 408px;
  padding: 48px 50px 50px;
  background: #ededed;
  right: -100%;
  -webkit-transition: right 0.3s ease;
  -o-transition: right 0.3s ease;
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    list-style: none;
    padding-bottom: 8px;
    font-size: 18px;

    &.divider {
      border-bottom: 1px solid #ccc;
      padding: 0;
      margin-bottom: 8px;
    }
  }
}

.mobile-menu-bottom {
  &__phones {
    background: url('../images/phone.svg') 0 center no-repeat transparent;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__address {
    background: url('../images/map.svg') 0 center no-repeat transparent;
  }

  &__icon {
    padding-left: 50px;
  }
}

@media (max-width: 407px) {
  .mobile-menu__wrapper {
    width: 100%;
  }
}