.search-results {
  position: relative;

  &__wrapper {
    border: 1px solid #c6c6c6;
    position: absolute;
    width: 100%;
    z-index: 14;
    background-color: #fff;
  }

  ul {
    list-style: none;

    li {
      list-style-type: none;
      padding: 2px 16px;

      &:hover {
        background-color: #fff2be;
      }

      &.search-results__all {
        a {
          background: none;
          text-decoration: none;
          color: #808080;
        }
      }

      a {
        font-family: "PR", sans-serif;
        font-weight: 400;
        padding-left: 18px;
        background: url(../images/chevron-right.png) 0 center no-repeat transparent;
        text-decoration: none;
      }
    }
  }
}