.nullify-offset-wrapper {
  display: flex;
  justify-content: flex-end;
}

.nullify-offset {
  cursor: pointer;
  height: 40px;
  background: url(../images/up-arrow.svg) center center no-repeat #838383;
  border-radius: 40px;
  width: 40px;
  position: fixed;
  bottom: 100px;
  z-index: 20;
  transition:visibility 0.3s linear, opacity 0.3s linear;
  opacity: 0;
  visibility: hidden;
}

@media (min-width: $breakpoint-mobile) {
  .nullify-offset {
    height: 50px;
    border-radius: 50px;
    width: 50px;
  }
}
