.frontpage-products {
  margin-top: 24px;
  margin-bottom: 60px;

  &__title {
    font-size: 40px;
    font-family: "PB", sans-serif;
    margin-bottom: 24px;
  }
}

.frontpage-slide {
  img {
    height: auto;
    width: 100%;
  }
}

@media (max-width: $breakpoint-mobile - 1) {
  .frontpage-products {
    &__title {
      font-size: 30px;
    }
  }
}
