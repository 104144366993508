.detailed-menu-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000033;
  z-index: 100;

  .content {
    display: flex;
    justify-content: end;
  }
}

.detailed-menu-modal {
  background-color: #fff;
  padding: 0 20px;
  width: 320px;
  position: absolute;
  bottom: 90px;

  &__close-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 14px;
    position: relative;
    margin-top: 14px;
  }

  &__close {
    border: medium none;
    width: 14px;
    height: 14px;
    mask: url(../images/product/close.svg) no-repeat center center;
    background-color: #838383;
  }

  .product__detailed-menu-sticky {
    margin-bottom: 0;
  }

  .product__mobile-detailed-menu {
    display: block !important;
  }
}

.mobile-detailed-menu-toggle {
  border: medium none;
  background: url(../images/product/phone-burger.svg) center center no-repeat #838383;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  z-index: 101;
}

.mobile-detailed-menu-button-wrapper {
  display: flex;
  justify-content: end;
  width: 100%;
}

@media (min-width: $breakpoint-mobile) {
  .mobile-detailed-menu-toggle {
    height: 50px;
    border-radius: 50px;
    width: 50px;
  }
}


@media (min-width: $breakpoint-laptop) {
  .mobile-detailed-menu-toggle {
    display: none;
  }
}