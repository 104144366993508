html {
  scroll-padding-top: 72px;
}

.product {
  font-family: 'PR', sans-serif;

  &__laptop-title {
    font-size: 36px;
    font-weight: bold;
    display: none;
    margin-bottom: 24px;
  }

  &__mobile-title {
    display: block;
  }

  &__content {
    display: flex;
    column-gap: 32px;
    width: 100%;
    flex-direction: column;
  }

  &__category-title {
    color: #212529;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  &__image-wrapper {
    max-width: 530px;
    flex: 0 0 45%;
    width: 100%;
    justify-content: center;
    display: flex;
    margin-bottom: 32px;
  }

  &__characteristics-wrapper {
    order: 3;
    margin-bottom: 32px;
    //flex: 0 0 35%;
  }

  &__actions-wrapper {
    margin-bottom: 32px;
    .similar-product-laptop-wrapper {
      display: none;
    }
  }

  &__image {
    border: 1px solid #E3E3E3;
    width: 437px;
    height: 437px;
    display: flex;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-bottom: 32px;
  }

  &__show-more-wrapper {
    margin-bottom: 32px;
  }

  &__show-more {
    max-height: 198px;
    overflow-y: hidden;
    margin-bottom: 8px;
  }

  &__show-more-expanded {
    max-height: unset !important;
    transition: all 3s ease-in;
  }

  &__show-more-button {
    color: #838383;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  &__description-feature {
    margin-top: 40px;
    font-size: 18px;
  }

  &__subtitle {
    margin-top: 40px;
    color: #7F7F7F;
    font-size: 18px;
  }

  &__read-more {
    margin-top: 20px;
    font-size: 18px;
    font-family: "PB", sans-serif;
    color: #D20F0D;
    text-decoration: underline;
  }

  &__price {
    color: #212529;
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 38px;
  }

  &__variants-wrapper {
    font-family: "PB", sans-serif;
    font-size: 18px;
    margin-bottom: 40px;
  }

  &__variants {
    display: flex;
  }

  &__variants-label {
    margin-bottom: 24px;
  }

  &__variants-links {
    display: flex;
    column-gap: 8px;
    a {
      font-size: 12px;
      border: 2px solid #F0F0F0;
      background: #F0F0F0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #000;
      padding: 14px;

      &.active {
        border: 2px solid #D30F0E;
        background: #fff;
      }
    }
  }

  &__details-content-text {
    font-size: 18px;
    line-height: 1.5em;
  }

  &__page-description {
    margin-top: 30px;
  }

  &__specifications {
    display: flex;
    gap: 32px;
    margin-bottom: 32px;
    flex-wrap: wrap;
  }

  &__specifications-wrapper {
    display: flex;
    align-items: center;
    background-color: #F7F7F7;
    color: #212529;
    cursor:pointer;
    font-size: 18px;
    border-radius: 100px;
    padding: 24px 24px 24px 24px;

    #product-spec-icon {
      stroke: #838383;
    }

    #product-qmark-icon {
      fill: #838383;
    }

    &:hover {
      color: #D30F0E;

      #product-spec-icon {
        stroke: #D30F0E;
      }

      #product-qmark-icon {
        fill: #D30F0E;
      }
    }
  }

  &__specifications-spec-name{
    margin: 0 24px;
  }

  &__files {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 32px;
    margin-bottom: 32px;

    &__file-wrapper {
      cursor: pointer;
      display: flex;
      font-weight: bold;
      flex: 0 1 calc((100% / 2) - 32px);
      justify-content: space-between;
      color: #838383;
      background-color: #F7F7F7;
      padding: 24px;
      position: relative;
      &:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px;
        border-color: transparent transparent transparent #fff;
        position: absolute;
        left: 0;
      }
    }

    &__file-name {
      margin-bottom: 16px;
      font-family: 'PB', sans-serif;
      font-size: 16px;
      font-weight: 700;
      line-height: 18.4px;

      a {
        color: #212529;
      }
    }

    &__file-extension {
      color: #fff;
      background: #838383;
      padding: 8px 12px
    }

    &__file-actions {
      position: relative;
      display: flex;
      gap: 24px
    }

    &__download {
      visibility: hidden;
      color: #D30F0E;
      span {
        mask: url(../images/product/eye.svg) no-repeat;
        mask-position: bottom left;
        mask-size: contain;
        background-color: #D30F0E;
        display: inline-block;
        height: 14px;
        width: 14px;
        margin-right: 4px;
      }
    }

    .product__files__file-wrapper {
      &.hover {
        .product__files__file-name {
          a {
            color: #D30F0E;
            text-decoration: none;
          }
        }
        .product__files__file-extension {
          background: #D30F0E;
        }
        .product__files__download {
          visibility: visible;
        }
      }
    }
  }

  &__shipping-payments-content-wrapper {
    padding: 24px 24px 24px 132px;
    position: relative;
    margin-bottom: 24px;
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px;
      border-color: transparent transparent transparent #fff;
      position: absolute;
      left: 0;
      top: 52px;
    }
  }

  &__shipping-payments {
    margin-bottom: 32px;
  }

  &__shipping-payments-shipping {
    background: url(../images/product/delivery.svg) 24px 24px no-repeat #F0F0F0;
  }

  &__shipping-payments-payment {
    background: url(../images/product/payment.svg) 24px 24px no-repeat #F0F0F0;
  }

  &__characteristics {
    display: flex;
    flex-direction: column;
    gap: 12px;
    &__element {
      display: flex;
      column-gap: 24px;
      border-bottom: 1px solid #E3E3E3;
      padding-bottom: 16px;
    }
    &__element-name {
      color: #838383;
      font-family: 'PB', sans-serif;
      font-weight: bold;
      display: flex;
      flex: 0 0 50%;
      justify-content: space-between;
    }
  }

  &__detailed-info {
    display: flex;
    flex-direction: row;
    column-gap: 32px;
  }

  &__text-content {
    flex: 0 0 100%;
  }

  &__detailed-menu {
    display: none;
    a {
      display: flex;
      align-items: center;
      flex-direction: row;
      column-gap: 16px;
      color: #212529;
      text-decoration: none;
      border-bottom: 1px solid #E3E3E3;
      padding: 24px 0 24px 0;
      svg {
        stroke: #212529;
      }
      &:hover {
        color: #D30F0E;
        border-bottom: 1px solid #D30F0E;
        svg {
          stroke: #D30F0E;
        }
      }
    }
  }

  &__detailed-menu-sticky {
    position: sticky;
    top: 60px;
    margin-bottom: 32px;
  }

  .similar-product-mobile-wrapper {
    width: 100%;
  }

  .similar-product-slider {
    width: 100%;
  }

}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  .product {
    &__laptop-title {
      display: none;
    }

    &__mobile-title {
      display: block;
    }

    &__content {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__image-wrapper {
      height: 287px;
      flex: 0 0 46%;
    }

    &__image {
      width: 100%;
      height: auto;
    }

    &__actions-wrapper {
      flex: 0 0 48%;
    }

    &__characteristics-wrapper {
      flex: 0 0 100%;
    }

    &__category-title {
      font-size: 22px;
    }

    &__characteristics__element {
      font-size: 14px;
    }

    &__description {
      padding-left: 0;
    }

    &__files {
      flex-direction: row;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop) {
  .product {
    &__laptop-title {
      display: none;
    }

    &__mobile-title {
      display: block;
    }

    &__category-title {
      font-size: 22px;
    }

    &__characteristics__element {
      font-size: 14px;
    }

    &__content {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__image-wrapper {
      height: 498px;
      flex: 0 0 48%;
    }

    &__image {
      width: 100%;
      height: auto;
    }

    &__price {
      margin-bottom: 12px;
    }

    &__variants-label {
      margin-bottom: 12px;
    }

    &__variants-wrapper {
      margin-bottom: 16px;
    }

    &__actions-wrapper {
      flex: 0 0 48%;
      margin-bottom: 0;
    }

    &__characteristics-wrapper {
      flex: 0 0 100%;
    }

    &__description {
      padding-left: 0;
    }

    &__files {
      flex-direction: row;
    }

    &__files {
      flex-direction: row;
    }

    .similar-product-mobile-wrapper {
      display: none;
    }

    .similar-product-laptop-wrapper {
      display: block;
      margin-top: 16px;
      width: 408px;
    }
  }
}

@media (min-width: $breakpoint-laptop) {
  .product {
    &__laptop-title {
      display: block;
    }

    &__mobile-title {
      display: none;
    }

    &__category-title {
      font-size: 24px;
    }

    &__characteristics__element {
      font-size: 16px;
    }

    &__content {
      flex-direction: row;
    }

    &__image-wrapper {
      height: 498px;
      flex: 0 0 348px;
    }

    &__image {
      height: 498px;
      width: 100%;
    }

    &__characteristics-wrapper {
      flex: 1 0 32%;
      order: unset;
    }

    &__actions-wrapper {
      flex: 0 0 32%;
    }

    &__text-content {
      flex: 0 0 67%;
    }

    &__detailed-menu {
      display: block;
      flex: 0 0 30%;
    }

    &__files {
      flex-direction: row;
    }

    .similar-product-laptop-wrapper {
      display: block;
      margin-top: 42px;
      width: 360px;
    }

    .similar-product-mobile-wrapper {
      display: none;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .product {
    &__laptop-title {
      display: block;
    }

    &__mobile-title {
      display: none;
    }

    &__image-wrapper {
      height: 498px;
      flex: 0 0 32%;
    }

    &__image {
      height: 498px;
      width: 500px;
    }

    &__actions-wrapper {
      flex: 0 0 360px;
    }

    &__text-content {
      flex: 1 0 70%;
    }

    &__detailed-menu {
      display: block;
      flex: 0 0 360px;
    }
  }
}