.swiper-container {
  overflow: hidden;
  position: relative;
}

.swiper-slide {
  padding-right: 20px;
  .product-preview {
    width: 100%;
  }
}

.swiper-pagination-bullets {
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  bottom: -8px !important;
}

.swiper-pagination-bullet {
  background: #E6E6E6;
  text-indent: -99999px;
  border: none;
  width: 14px;
  height: 14px;
  cursor: pointer;
  transition: 0.3s;
  margin: 10px;
  border-radius: 0 !important;
}

.swiper-pagination-bullet-active {
  background: #D30F0E;
}
