.similar-product {
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  margin-bottom: 24px;
  border-bottom: 1px solid #E3E3E3;
  align-items: center;
  padding-bottom: 24px;
  justify-content: space-between;
  flex-wrap: wrap;

  &__image {
    border: 1px solid #E3E3E3;
    padding: 8px;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 60px;

    img {
      height: auto;
      width: 100%;
    }
  }

  &__name-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: normal;
    flex: 1 0 calc(100% - 92px);
  }

  &__name {
    flex: 1 0 380px;
    margin-bottom: 16px;
    a {
      color: #212529;
      font-weight: bold;
      font-family: 'PB', sans-serif;
      &:hover {
        color: #D30F0E;
      }
    }
  }

  &__category {
    flex-grow: 50;
    color: #838383;
  }

  &__price {
    font-weight: bold;
    display: none;
  }

  &__cart {
    margin-left: 90px;
    input[type='submit'] {
      color: #D30F0E;
      font-weight: bold;
      background: url(../images/to-cart.svg) no-repeat left center transparent;
      border: medium none;
      padding-left: 34px;
      text-transform: uppercase;
    }
  }

  &__cart_text {
    display: none;
  }
}

@media (min-width: $breakpoint-mobile) {
  .similar-product {
    flex-wrap: nowrap;
    &__name-wrapper {
      flex: 1 0 auto;
    }
    &__cart {
      margin-left: 0;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .similar-product {
    &__name-wrapper {
      flex-direction: row;
      flex: 1 0 auto;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .similar-product {
    &__name-wrapper {
      flex-direction: row;
      flex-grow: 100;
      justify-content: space-between;
    }

    &__price {
      display: block;
    }

    &__cart_price {
      display: none;
    }
    &__cart_text {
      display: block;
    }
  }
}
