.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  font-size: 14px;
  font-family: "PB", sans-serif;
  list-style: none;

  li {
    list-style-type: none;
  }

  a {
    display: block;
    color: #000000;
  }

  .page-item {
    color: #000000;
    min-height: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px;

    &.active {
      background: #D30F0E;
      a {
        color: #fff;
      }
    }
    &.page-item-next {
      a {
        background: url(../images/arrow-right.png) center center no-repeat transparent;
        width: 32px;
        height: 32px;
        text-indent: -10000px;
      }
    }
    &.page-item-prev {
      a {
        background: url(../images/arrow-left.png) center center no-repeat transparent;
        width: 32px;
        height: 32px;
        text-indent: -10000px;
      }
    }
  }
}