@font-face {
  font-family: 'PB';
  src: url('../fonts/Play-Bold.eot');
  src: local('вє'), url('../fonts/Play-Bold.woff') format('woff'), url('../fonts/Play-Bold.ttf') format('truetype'), url('../fonts/Play-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PR';
  src: url('../fonts/Play-Regular.eot');
  src: local('вє'), url('../fonts/Play-Regular.woff') format('woff'), url('../fonts/Play-Regular.ttf') format('truetype'), url('../fonts/Play-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

*{
  box-sizing:border-box;
  line-height:1.2em;
  outline:none;
}

*::before,*::after{
  box-sizing:border-box;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  font-family: "PR", sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &.with-overlay {
    overflow: hidden;
  }
}

h1 {
  font-size: 36px;
  font-family: "PB", sans-serif;
  margin-bottom: 32px;
}

.content-wrapper {
  flex: 1 0 auto;
  margin-bottom: 80px;
}

button, input {
  &:focus {
    outline: medium none;
  }
}

.content {
  width: 100%;
  margin: 0 auto;
}

.hidden {
  visibility: hidden !important;
  display: none !important;
}

.disabled-loading {
  width: 44px;
  height: 44px;
  background: url(../images/auth_load.svg) center center no-repeat;
  background-size: contain;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

.text-right {
  text-align: right;
}

@media (max-width: $breakpoint-small-mobile - 1) {
}

@media (max-width: $breakpoint-mobile - 1) {
  h1 {
    margin-bottom: 30px;
    font-size: 30px;
  }
}

@media (min-width: $breakpoint-small-mobile) and (max-width: $breakpoint-mobile - 1) {
  .content {
    width: 420px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  h1 {

  }
  .content {
    max-width: 680px;
    width: 100%;
    padding: 0 20px;
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop - 1) {
  .content {
    width: 852px;
  }
}

@media (min-width: $breakpoint-laptop) and (max-width: $breakpoint-desktop - 1) {
  .content {
    width: 1146px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .content {
    width: 1440px;
  }
}