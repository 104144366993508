.document-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000033;
  z-index: 100;
  display: flex;
  justify-content: center;
}

.document-modal {
  background-color: #fff;
  padding: 32px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__close-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 14px;
    position: relative;
  }

  &__close {
    border: medium none;
    width: 14px;
    height: 14px;
    mask: url(../images/product/close.svg) no-repeat center center;
    background-color: #838383;
    right: -4px;
    position: absolute;
    top: -14px;
  }

  &__content {
    flex: 1;
  }
}

@media (min-width: $breakpoint-small-mobile) and (max-width: $breakpoint-mobile - 1) {
  .document-modal {
    margin: 40px 0;
    width: 90%;
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  .document-modal {
    margin: 60px 0;
    width: 90%;
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop - 1) {
  .document-modal {
    margin: 60px 0;
    width: 900px;
  }
}

@media (min-width: $breakpoint-laptop) and (max-width: $breakpoint-desktop - 1) {
  .document-modal {
    margin: 60px 0;
    width: 900px;
  }
}

@media (min-width: $breakpoint-desktop) {
  .document-modal {
    margin: 60px 0;
    width: 1000px;
  }
}
