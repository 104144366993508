.user-useful-links {
  list-style: none;

  li {
    list-style-type: none;
    padding-bottom: 6px;

    a {
      color: #000;
    }
  }
}

.user-login-form-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 100;
}

.login-form {
  width: 100%;
  background: #EDEDED;
  padding: 90px 40px 40px;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 550px;

  &__close-button {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 40px;
    top: 34px;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      height: 2px;
      width: 40px;
      background: #000;
      top: 0;
      right: 0;
      transform-origin: right center;
      transform: rotate(-45deg);
    }

    &:after {
      content: "";
      position: absolute;
      height: 2px;
      background: #000;
      width: 40px;
      bottom: 0;
      right: 0;
      transform-origin: right center;
      transform: rotate(45deg);
    }
  }

  h2 {
    font-size: 30px;
    font-family: "PB", sans-serif;
  }

  .form-control {
    width: 100%;
    height: 54px;
    background: #DFDFDF;
    padding: 0 20px;
    border: none;
    font-size: 16px;
    margin-top: 30px;
  }

  .user_remember_me {
    margin-top: 40px;
    display: flex;

    input {
      display: none;
    }

    input+label {
      padding-left: 30px;
      position: relative;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      align-content: center;

      &:before {
        content: "";
        width: 16px;
        height: 16px;
        background: #DFDFDF;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -8px;
      }

      &:after {
        content: "";
        width: 10px;
        height: 7px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 3px;
        top: 50%;
        margin-top: -3px;
        background: url(../images/chk.svg) center center no-repeat;
        background-size: contain;
      }
    }

    input:checked+label:before {
      background: #D30F0E;
    }

    input:checked+label:after {
      opacity: 1;
      visibility: visible;
    }
  }

  .form-actions {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;

    input[type=submit] {
      border: none;
      color: #fff;
      background: #D30F0E;
      font-family: "PB", sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      justify-content: center;
      display: flex;
      align-content: center;
      min-width: 116px;
      height: 54px;
      cursor: pointer;
      transition: 0.3s;
      flex: 0 0 auto;
    }
  }

  .user-useful-links {
    margin-bottom: 0;
  }
}

.user-devise {
  h2 {
    font-size: 30px;
    font-family: "PB", sans-serif;
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 30px;
  }

  .form-group-invalid {
    color: #D30F0E;
  }

  .form-control {
    width: 100%;
    height: 54px;
    background: #DFDFDF;
    padding: 0 20px;
    border: none;
    font-size: 16px;
    &.is-invalid {
      background: #d30f0e3b;
    }
  }

  .form-actions {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;

    input[type=submit] {
      border: none;
      color: #fff;
      background: #D30F0E;
      font-family: "PB", sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      justify-content: center;
      display: flex;
      align-content: center;
      height: 54px;
      cursor: pointer;
      transition: 0.3s;
      flex: 0 0 auto;
      padding: 0 20px;

      &:hover, &:focus {
        background: #FF3D3D;
      }
    }
  }

  .user_remember_me {
    margin-top: 40px;
    display: flex;

    input {
      display: none;
    }

    input+label {
      padding-left: 30px;
      position: relative;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      align-content: center;

      &:before {
        content: "";
        width: 16px;
        height: 16px;
        background: #DFDFDF;
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -8px;
      }

      &:after {
        content: "";
        width: 10px;
        height: 7px;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 3px;
        top: 50%;
        margin-top: -3px;
        background: url(../images/chk.svg) center center no-repeat;
        background-size: contain;
      }
    }

    input:checked+label:before {
      background: #D30F0E;
    }

    input:checked+label:after {
      opacity: 1;
      visibility: visible;
    }
  }

  .form-actions {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;

    input[type=submit] {
      border: none;
      color: #fff;
      background: #D30F0E;
      font-family: "PB", sans-serif;
      font-size: 12px;
      text-transform: uppercase;
      text-align: center;
      justify-content: center;
      display: flex;
      align-content: center;
      min-width: 116px;
      height: 54px;
      cursor: pointer;
      transition: 0.3s;
      flex: 0 0 auto;
    }
  }

  .alert {
    &.alert-danger {
      font-family: "PB", sans-serif;
      background: #FF3D3D;
      padding: 20px;
      color: #fff;
      margin-bottom: 20px;
    }
  }
}

.user-profile-menu {
  display: flex;
  flex-direction: column;
   a {
     text-decoration: none;
     color: #232323;
     transition: color 0.3s ease;
     font-family: "PB", sans-serif;
     text-transform: uppercase;
     margin-bottom: 12px;

     &.active, &:hover {
       color: #D30F0E;
     }
   }
}

.user-wrapper {
  display: flex;
  flex-direction: row;
  .user-menu-wrapper {
    width: 264px;
    margin-right: 30px;
    display: none;
  }
  .user-devise, .user-orders {
    flex-grow: 1;
  }
}

@media (min-width: $breakpoint-tablet) {
  .user-wrapper {
    .user-menu-wrapper {
      display: block;
    }
  }
}