.global-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  background: transparent;

  &.active {
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }
}

.global-overlay-login {
  background: rgba(1,1,1,0.3);
  &.active {
    z-index: 15;
  }
}