.user-order {
  background-color: #F0F0F0;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__number {
    flex-grow: 1;
  }

  &__number-value {
    display: flex;
    flex-grow: 1;
    padding-right: 10px;

    &:after {
      content: "";
      flex: 1 1 auto;
      border-bottom: dotted 2px #000;
      margin-left: 10px;
      margin-bottom: 2px;
    }
  }

  &__address {
    margin-bottom: 12px;
  }

  &__info {
    display: flex;
    margin-bottom: 16px;

    div {
      margin-right: 16px;
    }
  }

  &__status {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 5px;
      margin-right: 6px;
    }

    &.user-order__status-new::before {
      background-color: #ffc107;
    }

    &.user-order__status-in_processing::before {
      background-color: #007bff;
    }

    &.user-order__status-completed::before {
      background-color: #28a745;
    }

    &.user-order__status-canceled::before {
      background-color: #dc3545;
    }
  }
}

.user-order-container {
  width: 100%;
}

@media (max-width: $breakpoint-small-mobile - 1) {
  .user-order__info {
    flex-direction: column;
  }
}