.frontpage-brands {
  margin-bottom: 40px;

  h2 {
    font-weight: bold;
  }

  &__items {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    &:after {
      content: "";
      flex: auto;
    }
  }

  &__item {
    display: flex;
    flex: 0 0 calc(19% - 10px);
    border: 1px solid #f0f0f0;
    height: 100px;
    margin-bottom: 24px;
    margin-right: calc(1% + 10px);
    padding: 6px;

    &:hover {
      border-color: #000;
    }

    img {
      display: block;
      width: 100%;
      object-fit: scale-down;
    }
  }
}

@media (max-width: $breakpoint-mobile - 1) {
  .frontpage-brands {
    &__item {
      height: 50px;
      flex: 0 0 calc(49% - 10px);
      margin-right: calc(1% + 10px);
    }
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  .frontpage-brands {
    &__item {
      height: 80px;
      flex: 0 0 calc(31% - 10px);
      margin-right: calc(2% + 10px);
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop - 1){
  .frontpage-brands {
    &__item {
      flex: 0 0 calc(24% - 10px);
      margin-right: calc(1% + 10px);
    }
  }
}