.catalog-filter{
    will-change: min-height;
}

.sidebar__inner{
    transform: translate3d(0, 0, 0);
    will-change: position, transform;
}

.is-affixed {
    .sidebar__inner {
        padding-right: 30px;
    }
    &.no-padding {
        .sidebar__inner {
            padding-right: 0;
        }
    }
}
