.header {
  font-family: "PB", sans-serif;
  color: #000;
  padding-top: 35px;
  margin: 0 auto 48px auto;
  width: 100%;

  a {
    color: #000;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    align-items: center;
    width: 100%;
    height: 48px;
    left: 0;
    padding: 54px 20px;
    top: 0;
    background: #fff;
    z-index: 5;
    margin-left: auto;
    margin-right: auto;
  }

  &__logo-link {
    display: flex;
    width: 180px;
    height: 80px;
    background: url(../images/logo.svg) center center no-repeat;
    background-size: contain;
  }

  &__phones {
    background: url(../images/phone.svg) 0 center no-repeat;
    display: flex;
    flex-direction: column;
    padding-left: 34px;
  }

  &__address {
    display: flex;

    a:before {
      content: "";
      width: 18px;
      height: 27px;
      background: url(../images/map.svg) center center no-repeat;
      background-size: contain;
      transform: rotate(0.0001deg);
      -ms-transform: rotate(0.0001deg);
      margin-right: 9px;
    }
  }

  &__icon a {
    display: flex;
    align-items: center;
    align-content: center;
    font-family: "PB", sans-serif;
    text-transform: uppercase;
    color: #000;
  }

  &__user {
    a:before {
      content: "";
      width: 17px;
      height: 19px;
      background: url(../images/lk.svg) center center no-repeat;
      background-size: contain;
      margin-right: 8px;
    }
  }

  &__cart {
    position: relative;

    a:before {
      content: "";
      width: 17px;
      height: 19px;
      background: url(../images/basket.svg) center center no-repeat;
      background-size: contain;
      margin-right: 8px;
    }
  }
}

.header-bottom {
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}

.header-catalog {
  display: flex;
  position: relative;
  width: 100%;
  height: 54px;

  & .header-catalog-frontpage {
    &.active {
      z-index: 10 !important;
    }
  }

  &.active {
    z-index: 12;
  }

  &__selector {
    width: 50%;
    font-family: "PB", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 4;

    button {
      color: #e5e5e5;
      cursor: pointer;
      background: #000000;
      width: 100%;
      height: 100%;
      border: medium none;
      outline: medium none;
      text-transform: uppercase;
      transition: 0.3s;
      display: block;
    }

    &.active {
      z-index: 12;

      .header-catalog__items {
        display: block;
      }

      button {
        background: #D30F0E;
        color: #fff;
      }
    }
  }

  &__item-wrapper {
    display: inline-block;
    padding-left: 20px;
    position: relative;
    transition: 0.3s;
    font-family: "PB", sans-serif;
    font-size: 16px;
    cursor: pointer;
  }

  &__items {
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    color: #000;
    text-align: left;
    padding: 24px;
    text-transform: none;
    background: #F3F3F3;
    z-index: 12;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  &__item {
    font-size: 16px;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }

    &.expandable {
      a.header-catalog__root {
        &:hover, &:focus {
          text-decoration: none;
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 8px;
          height: 2px;
          width: 8px;
          background: #000;
          transition: 0.3s;
        }

        &:after {
          content: "";
          position: absolute;
          left: 3px;
          top: 5px;
          height: 8px;
          width: 2px;
          background: #000;
          transition: background 0.3s;
        }
      }
    }

    &.expandable.expanded {
      a {
        color: #D20F0D;

        &:before {
          background: #D20F0D;
        }
        &:after {
          opacity: 0;
          visibility: hidden;
        }
      }
      .header-catalog__children-items {
        display: block;
        a {
          color: #000;
        }
      }
    }
  }

  &__children-items {
    display: none;
    li {
      font-family: "PR", sans-serif;
      font-size: 14px;
      margin-top: 10px;
      padding-left: 20px;
    }
  }
}

.header-search-bar {
  width: 100%;

  &__form {
    display: flex;
  }

  &__input {
    color: #999999;
    background: #F0F0F0;
    flex: 1 1 auto;
    padding: 0 24px;
    display: flex;
    align-items: center;
    align-content: center;
    border: none;
    font-size: 16px;
    width: 100%;
  }

  &__submit {
    width: 54px;
    height: 54px;
    text-indent: -99999px;
    border: medium none;
    background: #E3E3E3;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      content: "";
      width: 20px;
      height: 20px;
      background: url(../images/search.svg) center center no-repeat;
    }

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 0 5px 6px;
      border-color: transparent transparent transparent #f0f0f0;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -3px;
    }
  }
}

a.header-contacts-button {
  display: none;
  height: 54px;
  width: 362px;
  justify-content: space-around;
  background: #000;
  align-items: center;
  color: #e5e5e5;
}

.header-sticky {
  display: flex;
  width: 100%;
  left: 0;
  justify-content: center;
  height: 54px;
  background: #F5F5F5;
  z-index: 15;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: -54px;
  transition: 0.3s;

  &:after {
    content: "";
    position: absolute;
    bottom: -26px;
    width: 100%;
    height: 26px;
    background: url(../images/fixed_s.png) center center repeat-x;
    pointer-events: none;
  }

  &__container {
    display: flex;
  }

  &.header-sticked {
    position: fixed;
    top: 0;
    visibility: visible;
    opacity: 1;
  }

  &__logo {
    background: url(../images/logo.svg) 0 center no-repeat;
    background-size: contain;
    a {
      display: block;
      width: 136px;
      height: 48px;
    }
  }

  &__search {
    width: 100%;
    border-left: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;

    form {
      display: flex;
    }
    input {
      color: #999999;
      background: #F0F0F0;
      flex: 1 1 auto;
      padding: 0 24px;
      display: flex;
      align-items: center;
      align-content: center;
      border: none;
      font-size: 16px;
    }
    button {
      width: 54px;
      height: 54px;
      text-indent: -99999px;
      border: medium none;
      background: #F0F0F0;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &:after {
        content: "";
        width: 20px;
        height: 20px;
        background: url(../images/search.svg) center center no-repeat;
      }
    }
  }

  &__user {
    min-height: 54px;
    display: flex;
    align-items: center;
    align-content: center;

    a {
      display: flex;
      align-items: center;

      span {
        display: none;
      }

      &:before {
        content: "";
        width: 17px;
        height: 19px;
        background: url(../images/lk.svg) center center no-repeat;
        background-size: contain;
        margin-right: 8px;
      }
    }
  }

  &__cart {
    position: relative;
    min-height: 54px;
    display: flex;
    align-items: center;
    align-content: center;

    a {
      display: flex;

      span {
        display: none;
      }

      &:before {
        content: "";
        width: 17px;
        height: 19px;
        background: url(../images/basket.svg) center center no-repeat;
        background-size: contain;
        margin-right: 8px;
      }
    }
  }

  &__actions {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
  }
}

@media (max-width: $breakpoint-mobile - 1) {
  body {
    margin-top: 74px;
  }

  .header {
    max-width: 380px;

    &__top {
      position: fixed;

      &:after {
        content: "";
        position: absolute;
        bottom: -26px;
        width: 100%;
        height: 26px;
        background: url(../images/fixed_s.png) center center repeat-x;
        pointer-events: none;
        z-index: 4;
        left: 0;
      }
    }

    &__logo-link {
      width: 54px;
      height: 48px;
      background: url(../images/logo_m.svg) center center no-repeat;
    }

    &__user, &__cart {
      span {
        display: none;
      }
    }

    &__phones, &__address {
      display: none;
    }
  }

  .header-bottom {
    flex-wrap: wrap;
  }

  .header-catalog {
    margin-bottom: 30px;
  }

  .header-sticky {
    display: none;
  }
}

@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet - 1) {
  .header {
    max-width: 680px;
    padding: 35px 20px 0;

    &__top {
      height: 80px;
      display: flex;
      justify-content: space-between;
      position: relative;
      font-size: 14px;
      margin-bottom: 16px;

      &:after {
        display: none;
      }
    }

    &__logo-link {
      width: 78px;
      height: 70px;
      background: url(../images/logo_m.svg) center center no-repeat;
      background-size: contain;
    }

    &__user, &__cart {
      span {
        display: block;
      }
    }

    &__phones, &__address {
      display: none;
    }
  }

  .header-bottom {
    flex-wrap: nowrap;
  }

  .header-catalog {
    max-width: 320px;
    width: 100%;
    margin-right: 15px;
    padding: 0;
  }

  .header-search-bar {
    max-width: 320px;
    width: 100%;
    margin-left: 15px;
    padding: 0;
  }

  .header-sticky {

    &__container {
      width: 680px;
      padding: 0 20px;
    }

    &__logo {
      background: url(../images/logo_m.svg) 0 center no-repeat;
      width: 74px;
      height: 48px;
    }

    &__search {
      width: 370px;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop - 1) {
  .header {
    &__top {
      width: 852px;
    }

    &__logo-link {
      width: 180px;
      height: 80px;
      background: url(../images/logo.svg) center center no-repeat;
      background-size: contain;
    }

    &__phones, &__address {
      display: none;
    }
  }

  .header-catalog {
    width: 264px;
  }

  .header-search-bar {
    width: 558px;
  }

  .header-bottom {
    width: 852px;
  }

  .header-sticky {

    &__container {
      width: 852px;
      padding: 0 20px;
    }

    &__logo {
      width: 136px;
      height: 48px;
    }

    &__search {
      width: 422px;
    }

    &__user {
      a {
        span {
          display: flex;
        }
      }
    }

    &__cart {
      a {
        span {
          display: flex;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-laptop) and (max-width: $breakpoint-desktop - 1) {
  .header {
    &__top {
      width: 1146px;
    }

    &__logo-link {
      width: 180px;
      height: 80px;
      background: url(../images/logo.svg) center center no-repeat;
      background-size: contain;
    }

    &__phones {
      background: url(../images/phone.svg) 0 center no-repeat;
      display: flex;
      flex-direction: column;
      padding-left: 34px;
    }

    .header-catalog {
      width: 264px;
    }

    .header-search-bar {
      width: 460px;
    }

    .header-bottom {
      width: 1146px;
    }

    .header-contacts-button {
      display: flex;
    }

    &__mobile-menu {
      display: none;
    }
  }

  .header-sticky {

    &__container {
      width: 1146px;
      padding: 0 20px;
    }

    &__logo {
      width: 263px;
      height: 48px;
    }

    &__search {
      width: 522px;
    }

    &__user {
      a {
        span {
          display: flex;
        }
      }
    }

    &__cart {
      a {
        span {
          display: flex;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .header {
    &__top {
      width: 1440px;
      padding: 40px 0 72px 0;
    }

    &__phones {
      display: flex;
    }

    &__address {
      display: flex;
    }

    &__mobile-menu {
      display: none;
    }

    .header-catalog {
      width: 264px;
    }

    .header-search-bar {
      width: 754px;
    }

    .header-bottom {
      width: 1440px;
    }

    .header-contacts-button {
      display: flex;
    }
  }

  .header-sticky {

    &__container {
      width: 1440px;
    }

    &__logo {
      width: 263px;
      height: 48px;
    }

    &__search {
      width: 816px;
    }

    &__user {
      a {
        span {
          display: flex;
        }
      }
    }

    &__cart {
      a {
        span {
          display: flex;
        }
      }
    }
  }
}