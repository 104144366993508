.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  font-size: 14px;
  color: #000;
  font-family: "PB", sans-serif;
  list-style: none;
  padding-left: 0;
  a {
    color: #000;
  }
}

.breadcrumb-item {
  display: flex;

  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item {
    padding-left: 8px;

    &::before {
      display: inline-block; // Suppress underlining of the separator in modern browsers
      padding-right: 10px;
      color: #000000;
      content: '/';
    }
  }
}