.content-wrapper.content-wrapper-frontpage {
  margin-bottom: 0;
}
.frontpage-description-wrapper {
  background-color: #F0F0F0;
  padding: 40px 0;

  .catalog-page-description {
    margin-top: 0;
  }
}
