.personal-title {
  line-height: 1.5em;
  font-size: 30px;
  font-family: "PB", sans-serif;
  padding-bottom: 10px;
  position: relative;
  margin-bottom: 30px;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background: #D30F0E;
    width: 50px;
  }
}

.personal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 90px;

  &__description-title {
    font-size: 24px;
    font-family: "PB", sans-serif;
    position: relative;
    margin-bottom: 20px;
  }

  &__description-text {
    line-height: 1.4em;
    font-size: 16px;
    margin-bottom: 40px;
  }

  &__managers-contact-us {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__manager-wrapper {
    background: #F4F4F4;
    padding: 24px 24px 0 24px;
  }

  &__manager-wrapper:last-of-type {
    .personal__manager {
      border-bottom: medium none;
    }
  }

  &__manager {
    border-bottom: 1px solid #E0E0E0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 24px;
  }

  &__manager-photo {
    flex: 1 0 25%;
    margin-bottom: 24px;
    img {
      height: 80px;
      width: 80px;
    }
  }

  &__manager-contacts {
    flex: 1 0 70%;
    white-space: nowrap;
    margin-bottom: 24px;
  }

  &__manager-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  &__manager-phone {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 4px;
    a {
      color: #1c2324;
    }
  }

  &__manager-email {
    font-size: 18px;
    a {
      color: #bdbdbd;
    }
  }

  &__manager-call {
    align-items: center;
    display: flex;
    text-transform: uppercase;
    a {
      background: url('../images/phone_contacts.svg') 20px center no-repeat;
      border: 1px solid #bdbdbd;
      color: #bdbdbd;
      display: block;
      padding: 12px 20px 12px 70px;
      transition: 0.3s;
      height: 48.5px;
      width: 173px;
      &:hover {
        color: #D30F0E;
        border: 1px solid #D30F0E;
        background: url('../images/phone.svg') 20px center no-repeat;
      }
    }
  }

  &__attributes {
    display: flex;
    flex-direction: column;
  }

  &__attribute {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
  }

  &__attribute-name {
    font-weight: bold;
    width: 160px;
  }

  &__attribute-value {
    width: auto;
  }
}

.presence-card {
  &__title {
    line-height: 1.5em;
    font-size: 30px;
    font-family: "PB", sans-serif;
    padding-bottom: 10px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      background: #D30F0E;
      width: 50px;
    }
  }

  &__description {
    margin-top: 30px;
    line-height: 1.4em;
    font-size: 16px;
  }

  &__map-container {
    margin-top: 60px;
  }

  &__map {
    height: 560px;
  }
}

@media (max-width: $breakpoint-mobile - 1) {
  .personal {
    flex-direction: column;
    margin-bottom: 30px;

    &__description {
      width: 100%;
    }

    &__description-title {
      font-size: 24px;
      line-height: 1.2em;
    }

    &__description-text {
      line-height: 1.3em;
    }
  }
}

@media (min-width: $breakpoint-mobile) {
  .personal {
    flex-direction: column;
    margin-bottom: 60px;

    &__manager {
      flex-wrap: nowrap;
    }

    &__manager-photo {
      flex: 0 0 auto;
      margin-right: 24px;
      margin-bottom: 0;
    }

    &__manager-contacts {
      white-space: nowrap;
      flex: 1 0 auto;
      margin-bottom: 0;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-laptop - 1) {
  .personal {
    flex-direction: column;
    margin-bottom: 60px;

    &__manager-wrapper {
      padding: 30px 40px 0 40px;
    }

    &__manager {
      padding-bottom: 30px;
    }
  }
}

@media (min-width: $breakpoint-laptop) and (max-width: $breakpoint-desktop - 1) {
  .personal {
    flex-direction: row;

    &__description {
      padding-right: 40px;
    }

    &__managers {
      flex: 1 0 56%;
    }

    &__manager-wrapper {
      padding: 30px 40px 0 40px;
    }

    &__manager {
      padding-bottom: 30px;
    }

    &__manager-photo {
      margin-right: 40px;
    }

    &__manager-contacts {
      flex-grow: 1;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .personal {
    flex-direction: row;
    margin-bottom: 60px;

    &__description {
      padding-right: 60px;
    }

    &__manager-wrapper {
      padding: 30px 40px 0 40px;
    }

    &__manager {
      padding-bottom: 30px;
    }

    &__manager-photo {
      margin-right: 40px;
    }

    &__manager-contacts {
      flex-grow: 1;
      margin-right: 40px;
    }
  }
}
