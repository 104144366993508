.catalog {
  .catalog-title{
    font-size: 36px;
    line-height: 41px;
    font-weight: 700;
    margin-bottom: 32px;
    @media(max-width: 1024px){
      font-size: 30px;
      line-height: 34px;
    }
    @media(max-width: 768px){
      font-size: 28px;
      line-height: 32px;
    }
    @media(max-width: 575px){
      font-size: 24px;
      line-height: 28px;
    }
  }
  .filter-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    margin-bottom: 24px;
  }
  .filter-row__main{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    @media(max-width: 480px){
      gap: 12px;
    }
  }

  .filter-row__subs{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
  .filter-results{
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    @media(max-width: 575px){
      display: none;
    }
  }
  .filter-views{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .view-item{
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #838383;
    transition: .2s ease-in-out;
  }
  .view-item:hover{
    color: #212529;
  }
  .view-item.active-item{
    color: #D30F0E;
  }
  .view-item.active-item:hover{
    color: #D30F0E;
  }
  .catalog-content{
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-bottom: 36px;
  }
  .column-filter{
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 268px;
    width: 100%;
    flex-shrink: 0;
    @media(max-width: 1366px){
      max-width: 260px;
    }
    @media(max-width: 1024px){
      max-width: 240px;
    }
    @media(max-width: 992px){
      display: none;
    }
  }
  .column-content{
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }
  .filter-tabs{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    width: 100%;
    @media(max-width: 575px){
      flex-wrap: nowrap;
      overflow-x: auto;
    }
  }
  .filter-tabs::-webkit-scrollbar{
    display: none;
  }
  .filter-tab{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 34px;
    background-color: #D30F0E;
  }
  .filter-tab__name{
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    color: #FFFFFF;
    white-space: nowrap;
  }
  .filter-tab__remove{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 12px;
    color: #FFFFFF;
    cursor: pointer;
  }
  .content-grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    width: 100%;
    @media(max-width: 1366px){
      grid-template-columns: repeat(3, 1fr);
    }
    @media(max-width: 680px){
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }
  .content-grid.mini-grid{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 0;
    @media(max-width: 1366px){
      grid-template-columns: repeat(4, 1fr);
    }
    @media(max-width: 768px){
      grid-template-columns: repeat(3, 1fr);
    }
    @media(max-width: 575px){
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .content-grid.mini-grid .product-btn{
    width: 60px;
  }
  .content-grid.mini-grid .product-btn svg{
    width: 18px;
    height: 18px;
  }
  .content-grid.mini-grid .product-price{
    font-size: 16px;
    line-height: 18px;
  }
  .content-grid.mini-grid .product-counter{
    display: none;
  }
  .content-grid.mini-grid .product-actions{
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
    justify-content: space-between;
  }
  .content-grid.mini-grid .product-values{
    width: fit-content;
  }

  .product{
    display: none;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid #F0F0F0;
    &.active {
      display: flex;
    }
  }
  .product-image{
    width: 100%;
    height: fit-content;
    position: relative;
  }
  .product-image img{
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }
  .product-tags{
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .product-tag{
    padding: 8px;
    border-radius: 30px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    width: fit-content;
  }
  .product-tag.hit{
    color: #DCBE19;
    background-color: #FFFDD0;
  }
  .product-tag.sale{
    color: #E35539;
    background-color: #FFD8D0;
  }
  .product-tag.discount{
    color: #5FB942;
    background-color: #D3FFC5;
  }
  .product-info{
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-height: 100px;
  }
  .product-info__name{
    display: block;
    width: 100%;
    height: 54px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    a {
      color: #212529;
    }
    @media(max-width: 575px){
      font-size: 14px;
      line-height: 16px;
      height: 48px;
    }
  }
  .product-props{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }
  .product-props__item{
    display: block;
    padding: 8px;
    border: 2px solid #F0F0F0;
    background-color: #F0F0F0;
    color: #212529;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    cursor: pointer;
    white-space: nowrap;
    @media(max-width: 575px){
      font-size: 11px;
      line-height: 13px;
      padding: 6px;
    }
  }
  .product-props__item.checked{
    background-color: #FFFFFF;
    border-color: #D30F0E;
    cursor: default;
  }
  .product-actions{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .product-price{
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    @media(max-width: 575px){
      font-size: 16px;
      line-height: 18px;
    }
  }
  .product-values{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
  }
  .product-counter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    padding: 12px;
    background-color: #F0F0F0;
  }
  .product-counter__btn{
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #838383;
  }
  .product-counter__input{
    width: 100%;
    text-align: center;
    border: none;
    outline: none;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    background-color: transparent;
  }
  .product-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 40px;
    background-color: #D30F0E;
    cursor: pointer;
    flex-shrink: 0;
    border: none;
    outline: none;
    @media(max-width: 680px){
      width: 60px;
    }
  }
  .product-select{
    position: relative;
    width: 100%;
  }
  .product-select__main{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: 2px solid #E3E3E3;
    cursor: pointer;
  }
  .product-select__active{
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
  }
  .product-select__list{
    display: none;
  }
  .product-select__list.open{
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 12px;
    background-color: #FFFFFF;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    z-index: 999;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    height: 142px;
    overflow: auto;
  }
  .product-select__list-item{
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    cursor: pointer;
  }
  .product-more{
    text-align: center;
    padding: 13px 26px;
    background-color: #F3F3F3;
    width: 100%;
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
  }
  .content-pagination{
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    width: 100%;
  }
  .pagination-prev-btn, .pagination-next-btn{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    background-color: #F0F0F0;
    cursor: pointer;
    transition: .2s ease-in-out;
  }
  .pagination-prev-btn:hover, .pagination-next-btn:hover {
    background-color: #D30F0E;
    color: #FFFFFF;
  }
  .pagination-page{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #F0F0F0;
    color: #212529;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
  }
  .pagination-page.active-page{
    background-color: #D30F0E;
    color: #FFFFFF;
  }
  .catalog-seo{
    padding-top: 32px;
    border-top: 1px solid #F0F0F0;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .catalog-seo__text{
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }
  .catalog-seo__list{
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style: none;
  }
  .catalog-seo__list-item{
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    position: relative;
    padding-left: 16px;
  }
  .catalog-seo__list-item:after{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #838383;
  }
  .filter-btn{
    width: 268px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    color: #212529;
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    user-select: none;
    pointer-events: none;
    @media(max-width: 1366px){
      width: 260px;
    }
    @media(max-width: 1024px){
      width: 240px;
    }
    @media(max-width: 992px){
      user-select: unset;
      pointer-events: unset;
      width: fit-content;
    }
    @media(max-width: 575px){
      font-size: 16px;
      line-height: 18px;
      gap: 12px;
    }
  }
  .sort-field{
    position: relative;
    @media(max-width: 575px){
      display: none;
    }
  }
  .m__sort-field{
    display: none;
    @media(max-width: 575px){
      display: block;
    }
  }
  .sort-field__main{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: fit-content;
    position: relative;
    cursor: pointer;
    padding-right: 30px;
    @media(max-width: 575px){
      gap: 12px;
      padding-right: 20px;
    }
  }
  .sort-arrow{
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
  .sort-field__name{
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    @media(max-width: 575px){
      font-size: 16px;
      line-height: 18px;
    }
  }
  .sort-field__list{
    display: none;
  }
  .sort-field__list.open{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    position: absolute;
    top: calc(100% + 4px);
    background-color: #FFFFFF;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    z-index: 999;
  }
  .sort-field__list-item{
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    color: #212529;
    cursor: pointer;
    white-space: nowrap;
  }

  .custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .custom-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
    width: 100%;
  }
  .custom-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    background-color: #E3E3E3;
    margin-right: 12px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  .custom-checkbox:checked+label::before {
    background-color: #D30F0E;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  }
  .filter-item__item{
    position: relative;
  }
  .filter-item__label-content{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    gap: 4px;
    width: 100%;
    padding-right: 16px;
  }
  .filter-item__label-content.empty {
    user-select: none;
    cursor: default;
  }
  .filter-item__label-content.empty .filter-item__name{
    color: #E3E3E3;
  }
  .filter-item__label-content.empty .filter-item__line, .filter-item__label-content.empty .filter-item__value{
    display: none;
  }
  .filter-item__name{
    display: block;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    flex-shrink: 0;
    width: fit-content;
  }
  .filter-item__line{
    display: block;
    width: 100%;
    height: 1px;
    border: 1px dashed #E3E3E3;
  }
  .filter-item__value{
    display: block;
    width: fit-content;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #838383;
    flex-shrink: 0;
  }
  .filter-item__list{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    max-height: 246px;
    height: fit-content;
    overflow: auto;
  }
  .filter-item__list::-webkit-scrollbar {
    width: 4px;
    background-color: #E3E3E3;
  }

  .filter-item__list::-webkit-scrollbar-thumb {
    background-color: #838383;
  }

  .filter-item__list::-webkit-scrollbar-track {
    background-color: #E3E3E3;
  }
  .filter-item__search{
    width: 100%;
    position: relative;
  }
  .filter-item__search input{
    width: 100%;
    padding: 13px 42px 13px 12px;
    outline: none;
    appearance: none;
    border: 1px solid #E3E3E3;
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    color: #212529;
  }
  .filter-item__search input::placeholder{
    color: #838383;
  }
  .filter-search__btn{
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: #E3E3E3;
    transition: .2s ease-in-out;
  }
  .filter-search__btn:hover{
    color: #212529;
  }
  .filter-item__content{
    margin-top: 20px;
    position: relative;
  }
  .filter-item{
    padding: 20px 0;
    border-top: 1px solid #F0F0F0;
  }
  summary{
    position: relative;
    list-style: none;
    cursor: pointer;
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
  }
  summary::-webkit-details-marker {
    display: none;
  }
  details summary:before{
    display: block;
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    width: 12px;
    height: 8px;
    //background-image: url("/assets/images/arrow-filter.png");
    background-position: center;
    background-repeat: no-repeat;
  }
  details[open] summary::before {
    transform: translateY(-50%) rotate(0);
  }
  .filter-select{
    position: relative;
  }
  .filter-select__field{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    padding: 13px 12px;
    border: 1px solid #E3E3E3;
    cursor: pointer;
  }
  .filter-select__list{
    display: none;
  }
  .filter-select__list.open{
    display: flex;
    flex-direction: column;
    padding: 20px 12px;
    gap: 20px;
    background-color: #FFFFFF;
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  .filter-select__list-item{
    display: block;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    cursor: pointer;
  }
  .rs-container{
    font-family: 'Play', sans-serif;
  }
  .rs-container .rs-scale{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 20px);
    top: 0;
  }
  .rs-container .rs-scale span:first-child,
  .rs-container .rs-scale span:last-child {
    display: block;
  }
  .rs-container .rs-scale span{
    display: none;
  }
  .rs-container .rs-bg, .rs-container .rs-selected{
    background-color: #F0F0F0;
    height: 4px;
    top: 6px;
  }
  .rs-container .rs-selected{
    background-color: #D30F0E;
    border: 1px solid #D30F0E;
  }
  .rs-container .rs-pointer{
    width: 16px;
    height: 16px;
    background-color: #FFFFFF;
    border: 2px solid #D30F0E;
    border-radius: 50%;
    cursor: pointer;
  }
  .rs-container .rs-pointer:before{
    display: none;
  }
  .rs-container .rs-pointer:after{
    display: none;
  }
  .rs-container .rs-scale span ins{
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    color: #838383;
  }

  .filter-inputs{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
    margin-bottom: 24px;
  }
  .filter-inputs span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12px;
    height: 3px;
  }
  .filter-input{
    width: 100%;
    padding: 12px;
    border: 1px solid #E3E3E3;
    font-size: 16px;
    line-height: 18px;
    appearance: none;
    outline: none;
  }
  .btn {
    cursor: pointer;
    border-radius: 0;
    width: 100%;
    padding: 12px 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    border: none;
  }
  .btn.black-btn{
    background-color: #212529;
    border: 1px solid #212529;
    color: #FFFFFF;
  }
  .btn.transparent-btn{
    background-color: transparent;
    border: 1px solid #838383;
    color: #838383;
  }

  .content-row{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .product-item{
    display: none;
    flex-direction: row;
    gap: 24px;
    width: 100%;
    padding: 24px 0;
    border-bottom: 1px solid #F0F0F0;
    &.active {
      display: flex;
    }
    @media(max-width: 768px){
      gap: 20px;
    }
  }
  .product-item .product-image{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border: 1px solid #E3E3E3;
    flex-shrink: 0;
  }
  .product-item__content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    @media(max-width: 768px){
      gap: 20px;
    }
    @media(max-width: 640px){
      flex-direction: column;
      gap: 8px;
    }
  }
  .product-item__info{
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
  .product-item .product-info__name{
    height: auto;
    width: fit-content;
  }
  .product-item__props{
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
    max-width: 645px;
    width: 100%;
    @media(max-width: 640px){
      max-width: unset;
    }
  }
  .product-item__props-item{
    display: block;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }
  .product-item .product-select{
    max-width: 180px;
    width: 100%;
    flex-shrink: 0;
    height: fit-content;
    @media(max-width: 768px){
      max-width: 140px;
    }
    @media(max-width: 640px){
      max-width: 160px;
    }
  }
  .product-item .product-select__main{
    height: 40px;
  }
  .product-item__tags{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .product-item .product-btn{
    max-width: 160px;
    width: 100%;
    text-align: center;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    flex-shrink: 0;
  }
  .product-btn__text{
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #FFFFFF;
  }
  .product-item .product-props{
    max-width: 180px;
    width: 100%;
    flex-shrink: 0;
    height: fit-content;
    @media(max-width: 768px){
      max-width: 140px;
    }
    @media(max-width: 640px){
      max-width: unset;
    }
  }
  .product-item .product-props__item{
    height: 40px;
    line-height: 24px;
  }
  .mobile-filter{
    display: none;
  }
  .mobile-filter.open{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100svh;
    overflow-y: auto;
    max-width: 308px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    background-color: #FFFFFF;
    z-index: 9999;
  }
  .mobile-filter.open::-webkit-scrollbar{
    display: none;
  }
  .mobile-filter__header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .btn-remove{
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-decoration: underline;
    color: #838383;
  }
  .btn-close{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: transparent;
    height: 14px;
    width: 14px;
    border: none;
  }

  .mobile-sort{
    display: none;
  }
  .mobile-sort.open{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background-color: #FFFFFF;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .mobile-sort__header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
  }
  .mobile-sort__title{
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
  }
  .mobile-sort__close{
    width: 14px;
    height: 14px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-sort__list{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .mobile-sort__item{
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
  }
  .mobile-sort__item.selected{
    color: #D30F0E;
  }
  .mobile-sort__hr{
    display: block;
    width: 100%;
    height: 1px;
    border: 1px solid #F0F0F0;
  }

  .filter-item__btn{
    position: absolute;
    z-index: 999;
    right: 0;
    transform: translate(100%, -30%);
    top: 0;
    //background-image: url("/assets/images/btn-bg.png");
    background-repeat: no-repeat;
    border: none;
    background-color: transparent;
    padding: 12px 12px 12px 20px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    color: #FFFFFF;
    cursor: pointer;
  }
}
